<template>
  <SectionWrapper
    id="trip-length"
    title="Trip length"
    :is-valid-section="isValidSection"
    :loading="isSubmitting"
    @saveSection="submit">
    <div class="my-4">
      <div class="flex items-center justify-between mb-2 pt-4 border-t">
        <h2 class="block text-lg font-medium text-gray-700">Duration</h2>
      </div>
      <div class="grid grid-cols-6 gap-6 pt-2">
        <div class="col-span-6 sm:col-span-2 lg:col-span-2">
          <InputNumberNight
            id="minimum-duration"
            v-model:value="minDuration"
            :max="maxDuration"
            label="Minimum duration"
            input-suffix="Nights" />
          <span class="input-error">{{errors.minDuration ?? ''}}</span>
        </div>

        <div class="col-span-6 sm:col-span-2 lg:col-span-2">
          <InputNumberNight
            id="maximum-duration"
            v-model:value="maxDuration"
            :min="minDuration"
            label="Maximum duration"
            input-suffix="Nights" />
          <span class="input-error">{{errors.maxDuration ?? ''}}</span>
        </div>
      </div>
    </div>

    <div class="mt-5 border bg-gray-50 rounded-lg p-4">
      <SwitchGroup as="div" class="flex items-center justify-between">
        <span class="flex-grow flex flex-col">
          <SwitchLabel as="span" class="font-medium text-gray-800" passive>
            Discount
          </SwitchLabel>
          <SwitchDescription as="span" class="text-sm text-gray-500">
            Give the guest a discount for staying longer periods.
          </SwitchDescription>
        </span>
        <Switch v-model="isDiscountEnabled"
                :class="[isDiscountEnabled ? 'bg-highlight-600' : 'bg-gray-200', 'relative inline-flex ' +
                  'flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer ' +
                  'transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ' +
                  'focus:ring-highlight-500']">
          <span aria-hidden="true" :class="[isDiscountEnabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none ' +
            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
        </Switch>
      </SwitchGroup>
      <div v-if="isDiscountEnabled" class="mt-6">
        <div>
          <LengthRule
            v-for="(amountNightRule, index) in amountNightRules"
            :key="amountNightRule.id"
            :ruleNumber="index + 1"
            :rule="amountNightRule"
            @updateRule="updateRule"
            @removeRule="removeRule" />
          <div class="mt-2 flex justify-end px-2">
            <button class="text-highlight-500 font-medium hover:underline
              flex gap-1 items-center text-sm" @click="addRule">
              <PlusIcon class="h-4 w-4" /> Add rule
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <div class="flex items-center justify-between mb-2">
        <h2 class="block text-lg font-medium text-gray-800">Preferences</h2>
      </div>
      <div class="grid grid-cols-6 gap-6 pt-2">
        <div class="col-span-6 sm:col-span-3 lg:col-span-2">
          <InputNumberNight
            id="preparation-time"
            v-model:value="preparationTime"
            label="Preparation time"
            input-suffix="Days">
            <template v-slot:label-explanation>
              <Popper
                :hover="true"
                arrow
                placement="top"
                :content="`Guests can book if there are more than ${ preparationTime } days to their trip.
                This is calculated from the check-in time you set.`">
                <QuestionMarkCircleIcon class="h-4 w-4 text-water" />
              </Popper>
            </template>
          </InputNumberNight>
          <span>{{errors.preparationTime ?? ''}}</span>
        </div>
      </div>
    </div>
  </SectionWrapper>
</template>

<script>
import { ref, computed, watch } from "vue";
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { QuestionMarkCircleIcon } from '@heroicons/vue/solid'
import Popper from "vue3-popper";
import * as yup from "yup";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { v4 as uuidv4 } from "uuid";
import { useMutation } from "@vue/apollo-composable";
import InputNumberNight from "@/components/UI/Inputs/InputNumberNight";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import LengthRule from "@/components/views/Listing/PricingAndAvailability/Rules/LengthRule";
import { DELETE_PRICE_SETTING } from "@/graphql/products/mutations";

export default {
  name: "TripLength",
  components: {
    InputNumberNight,
    SectionWrapper,
    QuestionMarkCircleIcon,
    Popper,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    LengthRule
  },
  emits: ['checkIsUnsaved', 'checkIsValid', 'updateData'],
  setup(_, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isCreateProductMode = computed(() => store.state.product.mode === 'create');

    const isDurationEditable = ref(false);

    const units = computed(() => store.state.product.product.children);
    const nextCreateStep = computed(() => store.getters["product/getNextStepCreate"]);

    const toggleEditDuration = () => {
      isDurationEditable.value =  !isDurationEditable.value;
    };

    const unit = computed(() => store.getters["product/getChildById"](isCreateProductMode.value ?
      'create' : +route.meta.props.unitsList[0].id));

    const schema = yup.object().shape({
      maxDuration: yup.number().required()
        // eslint-disable-next-line consistent-return
        .when('minDuration', (minDuration) => {
          if (minDuration) {
            return yup.number().positive().transform((v, o) => (o === '' ? 0 : v)).nullable()
              .min(minDuration + 1, 'Maximum duration should be more than minimum duration')
          }
        }),
      minDuration: yup.number().required()
        .transform((v, o) => (o === '' ? 0 : v))
        // eslint-disable-next-line consistent-return
        .when('maxDuration', (maxDuration) => {
          if (maxDuration) {
            return yup.number().min(1).nullable()
              .max(maxDuration - 1, 'Minimum duration should be less than maximum duration')
          }
        }),
      preparationTime: yup.string().required(),
      amountNightRules: yup.array().of(yup.object())
    }, ['minDuration', 'maxDuration']);

    const { errors, handleSubmit, values, isSubmitting, submitCount } = useForm({
      initialValues: {
        maxDuration: unit.value.max_booking_duration,
        minDuration: unit.value.min_booking_duration,
        preparationTime: unit.value.preparation_before_booking,
        amountNightRules: unit.value?.price_settings ?
          unit.value?.price_settings.filter(setting => setting.type === 2).sort((a, b) => a.id - b.id) : []
      },
      validationSchema: schema
    })

    const { value: maxDuration } = useField('maxDuration');
    const { value: minDuration } = useField('minDuration');
    const { value: preparationTime } = useField('preparationTime');
    const { value: amountNightRules } = useField('amountNightRules');

    const isSectionDirty = useIsFormDirty();
    const isSectionValid = useIsFormValid();

    const isValidSection = computed(() => isSectionDirty.value && isSectionValid.value);

    const isUnsaved = computed(() => isSectionDirty.value && submitCount.value === 0);

    watch(isSectionValid, value => emit('checkIsValid', 'tripLength', value));

    watch(isUnsaved, value => {
      emit('checkIsUnsaved', {
        title: 'Trip Length'
      }, value)
    });

    emit('updateData', 'tripLength', values);

    watch(values, value => {
      emit('updateData', 'tripLength', value);
    });

    const updateRule = (id, price, value, type) => {
      amountNightRules.value = amountNightRules.value.map(setting => {
        if(setting.id === id) {
          return {
            id: setting.id,
            price:  Number(price),
            value: Number(value),
            active: true,
            type
          }
        }
        return setting;
      })
    };

    const addRule = () => {
      amountNightRules.value.push({
        id: uuidv4(),
        active: true,
        price: 0,
        value: 0,
        type: 2
      })
    };

    const isDiscountEnabled = ref(amountNightRules.value.every(rule => rule.active === true)
      && amountNightRules.value.length > 0);

    watch(isDiscountEnabled, value => {
      amountNightRules.value = amountNightRules.value.map(rule => ({
        ...rule,
        active: value
      }));

      if (isDiscountEnabled.value && amountNightRules.value.length === 0) {
        addRule();
      }
    })


    const { mutate: deleteRule } = useMutation(DELETE_PRICE_SETTING)

    const removeRule = (id) => {
      if (typeof id !== 'string') {
        deleteRule({
          id
        })
      }
      amountNightRules.value = amountNightRules.value.filter(rule => rule.id !== id);

      if (amountNightRules.value.length === 0) {
        isDiscountEnabled.value = false;
      }
    };

    const submit = handleSubmit( async ({
      maxDuration,
      minDuration,
      preparationTime,
      amountNightRules
    }, { resetForm }) => {
      if(isValidSection.value) {
        const reset = () => {
          resetForm({
            touched: false,
            values: {
              maxDuration: unit.value.max_booking_duration,
              minDuration: unit.value.min_booking_duration,
              preparationTime: unit.value.preparation_before_booking,
              amountNightRules: unit.value.price_settings ? unit.value.price_settings.filter(setting => setting.type === 2) : []
            }
          })
        }
        if(isCreateProductMode.value) {
          store.commit('product/setData', { field: 'children', step: 'children-trip-length', data: [{
              ...unit.value,
              max_booking_duration: +maxDuration,
              min_booking_duration: +minDuration,
              preparation_before_booking: +preparationTime,
              price_settings: [...unit.value.price_settings, ...amountNightRules]
          }] });
          reset();
          await router.push(nextCreateStep.value);
        } else {
          let priceSettings = [];

          if (unit.value.price_settings) {
            priceSettings.push(...unit.value.price_settings.filter(setting => setting.type !== 2));
          }

          priceSettings = [
            ...priceSettings,
            ...amountNightRules.map(rule => ({
              ...rule,
              id: typeof rule.id === 'string' ? undefined : rule.id
            }))
          ].map(setting => ({
            ...setting,
            __typename: undefined
          }));

          const preparedData = {
            children: units.value.map(child => {
              if(child.id === +route.meta.props.unitsList[0].id) {
                return {
                  id: child.id,
                  default_price: +unit.value.default_price,
                  max_booking_duration: +maxDuration,
                  min_booking_duration: +minDuration,
                  preparation_before_booking: +preparationTime,
                  price_settings: priceSettings
                }
              }
              return {
                ...child,
                __typename: undefined,
                translation: undefined,
                translations: child.translations.map(translation => ({
                  ...translation,
                  locale: 'en',
                  __typename: undefined
                })),
                price_settings: child.price_settings.map(setting => ({
                  ...setting,
                  __typename: undefined
                })),
                synchronization: child.synchronization.map(calendar => ({
                  ...calendar,
                  __typename: undefined
                }))
              };
            })
          };

          await store.dispatch('product/updateProduct', { id: +route.params.id, data: preparedData }).then(() => {
            reset();
          });
        }
      }
    });

    return {
      isDiscountEnabled,
      toggleEditDuration,
      maxDuration,
      minDuration,
      preparationTime,
      amountNightRules,
      errors,
      isValidSection,
      submit,
      addRule,
      removeRule,
      updateRule,
      isSubmitting
    }
  }
};
</script>

