import gql from "graphql-tag";

const GET_CHANNEL_URL = gql`
  query getChannelUrl($channelId: Int!, $productId: Int!) {
    getLinkByChannel(channel_id: $channelId, product_id: $productId)
  }
`;

const GET_CHANNELS = gql`
  query getChannels {
    getChannels {
      id,
      title
    }
  }
`;

const DELETE_SYNCHRONIZATION_LINK = gql`
  mutation deleteSynchronizationLink($id: Int!) {
    deleteExCal(id: $id) {
      id
    }
  }
`;

export {
  GET_CHANNEL_URL,
  GET_CHANNELS,
  DELETE_SYNCHRONIZATION_LINK
}
