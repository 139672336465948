<template>
  <SectionWrapper
    title="Calendar sync"
    :description=
      "`Make sure to look at our guides below for connections with specific calendars like Airbnb, Booking.com, Sirvoy, etc.
          <a class='relative cursor-pointer rounded-md font-medium text-highlight-600 hover:text-highlight-500' target='_blank' href='https://owayy.slab.com/public/posts/how-to-synchronize-your-owayy-calendar-with-third-party-calendars-i-cal-lelb9h15'>See guides here.</a>`"
    :is-valid-section="isValidSection"
    :show-save-button="!isCreateProductMode"
    @saveSection="submit">
    <div v-if="!loading && !isCreateProductMode" class="space-y-2 mt-3">
      <SyncItem
        v-for="(syncItem, index) in calendarSync"
        :key="syncItem.id"
        :syncItem="syncItem"
        :number="index + 1"
        :channels="channels"
        @removeLink="removeLink"
        @selectChannel="selectChannel"
        @inputImportUrl="inputImportUrl"
        @checkLink="checkLinkValidity" />
      <div class="pt-4">
        <button class="w-full flex items-center gap-2 justify-center px-6 py-8 border-2
        hover:bg-gray-50 border-gray-300 border-dashed rounded-md text-gray-700" @click="addLink">
          <PlusIcon class="h-4 w-4" /> Add calendar
        </button>
      </div>
    </div>
  </SectionWrapper>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useQuery, useMutation } from "@vue/apollo-composable";
import * as yup from "yup";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import { v4 as uuidv4 } from 'uuid';
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import SyncItem from "@/components/views/Listing/PricingAndAvailability/SyncItem";
import { DELETE_SYNCHRONIZATION_LINK, GET_CHANNELS } from "@/graphql/products/channels/channels";

export default {
  name: "CalSync",
  components: { SectionWrapper, SyncItem },
  emits: ['checkIsUnsaved', 'checkIsValid', 'updateData'],
  setup(_, { emit }) {
    const store = useStore();
    const route = useRoute();

    const isCreateProductMode = computed(() => store.state.product.mode === 'create');

    const { result, loading } = useQuery(GET_CHANNELS);

    const channels = computed(() => result.value?.getChannels);

    const units = computed(() => store.state.product.product.children);
    const unit = computed(() => store.getters["product/getChildById"](isCreateProductMode.value ?
      'create' : +route.meta.props.unitsList[0].id));
    const calendarSyncCreated = computed(() => unit.value.synchronization);

    const schema = yup.object({
      calendarSync: yup.array().of(yup.object())
    });

    const { errors, handleSubmit, values, submitCount } = useForm({
      initialValues: {
        calendarSync: [...calendarSyncCreated.value]
      },
      validationSchema: schema,
      validateOnMount: true
    })

    const { value: calendarSync } = useField('calendarSync');

    const isSectionDirty = useIsFormDirty();
    const isSectionValid = useIsFormValid();

    const isLinksValid = ref(true);

    const checkLinkValidity = (value) => {
      isLinksValid.value = value;
    }

    const isValidSection = computed(() => isSectionDirty.value && isSectionValid.value && isLinksValid.value);

    const isUnsaved = computed(() => isSectionDirty.value && submitCount.value === 0);

    watch(isSectionValid, value => emit('checkIsValid', 'calSync', value));

    watch(isUnsaved, value => {
      emit('checkIsUnsaved', {
        title: 'Calendar Sync'
      }, value)
    });

    emit('updateData', 'calSync', values);

    watch(values, value => {
      emit('updateData', 'calSync', value);
    });

    const submit = handleSubmit( async ({
      calendarSync
    }, { resetForm }) => {
      if(isValidSection.value) {
        const reset = () => {}
        resetForm({
          touched: false,
          values: {
            calendarSync: [...calendarSyncCreated.value]
          }
        });

        if(isCreateProductMode.value) {
          store.commit('product/setData', { field: 'children', data: [{
              ...unit.value,
              synchronization: calendarSync
          }] });
          reset();
        } else {
          const preparedData = {
            children: units.value.map(child => {
              if(child.id === +route.meta.props.unitsList[0].id) {
                return {
                  id: child.id,
                  default_price: +unit.value.default_price,
                  synchronization: calendarSync.map(calendar => ({
                    ...calendar,
                    id: typeof calendar.id === 'string' ? undefined : calendar.id,
                    __typename: undefined
                  }))
                }
              }
              return {
                ...child,
                __typename: undefined,
                translation: undefined,
                translations: child.translations.map(translation => ({
                  ...translation,
                  locale: 'en',
                  __typename: undefined
                })),
                price_settings: child.price_settings.map(setting => ({
                  ...setting,
                  __typename: undefined
                }))
              };
            })
          };

          await store.dispatch('product/updateProduct', { id: +route.params.id, data: preparedData }).then(() => {
            resetForm({
              touched: false,
              values: {
                calendarSync: [...calendarSyncCreated.value]
              }
            })
          });
        }
      }
    })

    const addLink = () => {
      calendarSync.value.push({
        id: uuidv4(),
        product_id: +route.meta.props.unitsList[0].id,
        link: "",
        channel_id: 0
      })
    }

    const { mutate: removeExCalLink } = useMutation(DELETE_SYNCHRONIZATION_LINK)

    const removeLink = (id) => {
      calendarSync.value = calendarSync.value.filter(calendar => calendar.id !== id);
      if (typeof id === "number") {
        removeExCalLink({
          id
        })
      }
    }

    const selectChannel = (value, id) => {
      calendarSync.value = calendarSync.value.map(calendar => {
        if (calendar.id === id) {
          return {
            ...calendar,
            channel_id: value.id
          }
        }
        return calendar;
      })
    }

    const inputImportUrl = (value, id) => {
      calendarSync.value = calendarSync.value.map(calendar => {
        if (calendar.id === id) {
          return {
            ...calendar,
            link: value
          }
        }
        return calendar;
      })
    }

    return {
      isCreateProductMode,
      unit,
      calendarSync,
      isValidSection,
      channels,
      loading,
      errors,
      addLink,
      removeLink,
      selectChannel,
      inputImportUrl,
      submit,
      checkLinkValidity
    }

  }
};
</script>

<style scoped>

</style>
