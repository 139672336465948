<template>
  <div class="inline-flex mx-1">
    <div class="relative border border-gray-300 rounded-md inline-flex cursor-text overflow-hidden
      focus-within:ring-1 focus-within:ring-highlight-500 focus-within:border-highlight-500">
      <input
        :id="id"
        type="number"
        :value="value"
        :min="min"
        :max="max"
        :name="id"
        class="no-arrows block text-center w-12 px-1 w-full py-0.5 sm:text-md border-none
              focus:outline-none focus:ring-0"
        @input="$emit('update:value', +$event.target.value)" />
      <label :for="id" class="cursor-text bg-gray-50 block px-1 right-0 flex items-center">
        <span class="text-gray-500 text-xs"> {{ inputSuffix }} </span>
      </label>
    </div>
    <span class="input-error"></span>
  </div>
</template>

<script>
export default {
  name: "SmInputNumber",
  emits: ['update:value'],
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    inputSuffix: {
      type: String,
      required: 'Nights'
    },
    min: {
      type: String,
      default: '0'
    },
    max: {
      type: String,
      default: '50'
    },
    value: {
      type: [Number, String]
    }
  }
};
</script>

<style scoped>

</style>
