<template>
  <SectionWrapper title="General" :description="`<a class='relative cursor-pointer rounded-md font-medium text-highlight-600 hover:text-highlight-500' target='_blank' href='https://owayy.slab.com/public/topics/owayy-dashboard-guides-clomok8i'>Learn to price your listing</a>`" :isValidSection="isValidSection" :loading="isSubmitting" @saveSection="submit">
    <div class="my-2">
      <div class="flex items-center justify-between mb-2 pt-4 border-t">
        <h2 class="block text-lg font-medium text-gray-700">Pricing</h2>
      </div>
      <InputNumberNight
        id="default-price"
        v-model:value="price"
        label="Nightly price"
        input-class="w-20"
        :input-suffix="currencyCode" />
      <span class="input-error">{{ errors.price }}</span>
      <div v-if="!isCreateProductMode" class="flex content-center items-center">
        <p class="text-sm text-gray-600">Clear price to default</p>
        <button
          class="text-sm px-2 py-1 border rounded ml-2 bg-gray-200 hover:bg-gray-100"
          @click="returnPricingToDefault">
          Clear
        </button>
      </div>
      <p class="text-sm text-gray-600">Don't worry. You can apply special pricing in the sections below.</p>
    </div>
    <div class="my-8">
      <div class="mt-5 border bg-gray-50 rounded-lg p-4">
        <SwitchGroup as="div" class="flex items-center justify-between">
          <span class="flex-grow flex flex-col">
            <SwitchLabel as="span" class="font-medium text-gray-800" passive> Special pricing </SwitchLabel>
            <SwitchDescription as="span" class="text-sm text-gray-500">
              Do you want to offer a special price on some days?
            </SwitchDescription>
          </span>
          <Switch
            v-model="isSpecialPricingEnabled"
            :class="[
              isSpecialPricingEnabled ? 'bg-highlight-600' : 'bg-gray-200',
              'relative inline-flex ' +
                'flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer ' +
                'transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ' +
                'focus:ring-highlight-500'
            ]">
            <span
              aria-hidden="true"
              :class="[
                isSpecialPricingEnabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none ' +
                  'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              ]" />
          </Switch>
        </SwitchGroup>
        <div v-if="isSpecialPricingEnabled" class="mt-6">
          <div>
            <div class="flex justify-between items-center pb-2">
              <p class="font-medium text-sm text-gray-700">Summary</p>
            </div>
            <div class="pb-3">
              <p v-for="(item, index) in summary" :key="item" :class="['text-sm text-gray-600', index !== 0 ? 'mt-2' : '']">
                {{ item }}
              </p>
            </div>
          </div>
          <div>
            <DayRule
              v-for="(dayRule, index) in dayRules"
              :key="dayRule.id"
              :ruleNumber="index + 1"
              :rule="dayRule"
              :preparedDays="preparedDays"
              @updateRule="updateRule"
              @removeRule="removeRule" />

            <div class="mt-2 flex justify-end px-2">
              <button
                v-if="dayRules.length < 7"
                class="text-highlight-500 font-medium hover:underline flex gap-1 items-center text-sm"
                @click="addRule">
                <PlusIcon class="h-4 w-4" /> Add rule
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SectionWrapper>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { PlusIcon } from "@heroicons/vue/solid";
import { useRoute, useRouter } from "vue-router";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import { useMutation } from "@vue/apollo-composable";
import DayRule from "@/components/views/Listing/PricingAndAvailability/Rules/DayRule";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import InputNumberNight from "@/components/UI/Inputs/InputNumberNight";
import { DELETE_PRICE_SETTING } from "@/graphql/products/mutations";
import { DELETE_CUSTOME_PRICING } from "@/graphql/calendar/mutations";
import { notify } from "@kyvg/vue3-notification";

const possibleDays = [
  {
    text: "Sunday",
    value: 0,
    active: true
  },
  {
    text: "Monday",
    value: 1,
    active: true
  },
  {
    text: "Tuesday",
    value: 2,
    active: true
  },
  {
    text: "Wednesday",
    value: 3,
    active: true
  },
  {
    text: "Thursday",
    value: 4,
    active: true
  },
  {
    text: "Friday",
    value: 5,
    active: true
  },
  {
    text: "Saturday",
    value: 6,
    active: true
  }
];

export default {
  name: "Pricing",
  components: {
    InputNumberNight,
    PlusIcon,
    SectionWrapper,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    DayRule
  },
  emits: ["checkIsUnsaved", "checkIsValid", "updateData"],
  setup(_, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const isCreateProductMode = computed(() => store.state.product.mode === 'create');
    const nextCreateStep = computed(() => store.getters["product/getNextStepCreate"]);

    const currencyCode = computed(() => store.getters["product/getCurrentCurrencyCode"]);
    const unit = computed(() => store.getters["product/getChildById"](isCreateProductMode.value ?
      'create' : +route.meta.props.unitsList[0].id));
    const units = computed(() => store.state.product.product.children);

    const schema = yup.object({
      price: yup.string().required(),
      dayRules: yup.array().of(yup.object())
    });

    const { errors, handleSubmit, values, isSubmitting, submitCount } = useForm({
      initialValues: {
        price: unit.value?.default_price,
        dayRules: unit.value?.price_settings
          ? unit.value?.price_settings.filter((setting) => setting.type === 3).sort((a, b) => a.id - b.id)
          : []
      },
      validationSchema: schema
    });

    const { value: price } = useField("price");
    const { value: dayRules } = useField("dayRules");

    const updateRule = (id, price, value, type) => {
      dayRules.value = dayRules.value.map((setting) => {
        if (setting.id === id) {
          return {
            id: setting.id,
            price: Number(price),
            value: Number(value),
            active: true,
            type
          };
        }
        return setting;
      });
    };

    const alreadySelectedDays = computed(() => dayRules.value.map((rule) => rule.value));

    const addRule = () => {
      if (dayRules.value.length >= 7) return;

      dayRules.value.push({
        id: uuidv4(),
        active: true,
        price: 0,
        value: possibleDays.filter((day) => !alreadySelectedDays.value.includes(day.value))[0].value,
        type: 3
      });
    };

    const { mutate: deleteRule } = useMutation(DELETE_PRICE_SETTING);

    const isSpecialPricingEnabled = ref(dayRules.value.every((rule) => rule.active === true) && dayRules.value.length > 0);

    const removeRule = (id) => {
      if (typeof id !== "string") {
        deleteRule({
          id
        });
      }
      dayRules.value = dayRules.value.filter((rule) => rule.id !== id);

      if (dayRules.value.length === 0) {
        isSpecialPricingEnabled.value = false;
      }
    };

    const preparedDays = computed(() =>
      possibleDays.map((day) => ({
        ...day,
        active: !alreadySelectedDays.value.includes(day.value)
      }))
    );

    const summary = computed(() =>
      dayRules.value.map(
        (rule) =>
          // eslint-disable-next-line vue/max-len
          `The price for ${preparedDays.value.find((day) => day.value === rule.value).text} is set to ${rule.price} ${
            currencyCode.value
          }/night`
      )
    );

    watch(isSpecialPricingEnabled, (value) => {
      dayRules.value = dayRules.value.map((rule) => ({
        ...rule,
        active: value
      }));

      if (isSpecialPricingEnabled.value && dayRules.value.length === 0) {
        addRule();
      }
    });

    const isSectionDirty = useIsFormDirty();
    const isSectionValid = useIsFormValid();

    const isValidSection = computed(() => isSectionDirty.value && isSectionValid.value);

    const isUnsaved = computed(() => isSectionDirty.value && submitCount.value === 0);

    watch(isSectionValid, (value) => emit("checkIsValid", "pricing", value));

    watch(isUnsaved, (value) => {
      emit(
        "checkIsUnsaved",
        {
          title: "Pricing"
        },
        value
      );
    });

    emit("updateData", "pricing", values);

    watch(values, (value) => {
      emit("updateData", "pricing", value);
    });

    const submit = handleSubmit(async ({ price, dayRules }, { resetForm }) => {
      if (isValidSection.value) {
        const reset = () => {
          resetForm({
            touched: false,
            values: {
              price: unit.value.default_price,
              dayRules: unit.value.price_settings ? unit.value.price_settings.filter((setting) => setting.type === 3) : []
            }
          });
        };
        if (isCreateProductMode.value) {
          store.commit('product/setData', { field: 'children', step: 'children-price', data: [{
            ...unit.value,
              default_price: +price,
              price_settings: [...unit.value.price_settings, ...dayRules]
          }] });
          reset();
          await router.push(nextCreateStep.value);
        } else {
          try {
            let priceSettings = [];

            if (unit.value.price_settings) {
              priceSettings.push(...unit.value.price_settings.filter((setting) => setting.type !== 3));
            }

            priceSettings = [
              ...priceSettings,
              ...dayRules.map((rule) => ({
                ...rule,
                id: typeof rule.id === "string" ? undefined : rule.id
              }))
            ].map((setting) => ({
              ...setting,
              __typename: undefined
            }));

            const preparedData = {
              children: units.value.map((child) => {
                if (child.id === +route.meta.props.unitsList[0].id) {
                  return {
                    id: child.id,
                    default_price: +price,
                    price_settings: priceSettings
                  };
                }
                return {
                  ...child,
                  __typename: undefined,
                  translation: undefined,
                  translations: child.translations.map((translation) => ({
                    ...translation,
                    locale: "en",
                    __typename: undefined
                  })),
                  price_settings: child.price_settings.map((setting) => ({
                    ...setting,
                    __typename: undefined
                  })),
                  synchronization: child.synchronization.map((calendar) => ({
                    ...calendar,
                    __typename: undefined
                  }))
                };
              })
            };
            await store.dispatch("product/updateProduct", { id: +route.params.id, data: preparedData }).then(() => {
              reset();
            });
          } catch (err) {
            notify({
              title: err,
              type: 'error'
            })
          }
        }
      }
    });

    const { mutate: returnPricingToDefault, onDone: onDeleteCustomePrice } = useMutation(DELETE_CUSTOME_PRICING, () => ({
      variables: {
        id: unit.value.id
      }
    }));

    onDeleteCustomePrice(() => {
      notify({
        title: "All days are set to default price",
        type: 'success'
      })
    });

    return {
      isSpecialPricingEnabled,
      currencyCode,
      unit,
      errors,
      price,
      isValidSection,
      dayRules,
      submit,
      units,
      preparedDays,
      updateRule,
      addRule,
      removeRule,
      summary,
      returnPricingToDefault,
      isCreateProductMode,
      isSubmitting
    };
  }
};
</script>

<style scoped></style>
