<template>
  <SectionWrapper
    id="guests"
    title="Guests"
    description="How many guests can stay at your place? Add the total amount of guests - you can set special prices for a specific number of guests below."
    :is-valid-section="isValidSection"
    :loading="isSubmitting"
    @saveSection="submit">
    <div>
      <div class="flex items-center justify-between mt-2">
        <span class="flex-grow flex flex-col">
          <span class="text-sm text-gray-700">
            How many guests are included in the price?
          </span>
        </span>
      </div>
      <div class="mt-2">
        <InputNumberNight
          id="capacity"
          v-model:value="capacity"
          input-suffix="Guests" />
        <span class="input-error">{{errors.capacity}}</span>
      </div>
      <div class="flex items-center justify-between mt-2">
        <span class="flex-grow flex flex-col">
          <span class="text-sm text-gray-700">
            Maximum amount of guests?
          </span>
        </span>
      </div>
      <div class="mt-2">
        <InputNumberNight
          id="maximum-capacity"
          v-model:value="maxCapacity"
          input-suffix="Guests" />
        <span class="input-error">{{errors.maxCapacity}}</span>
      </div>
    </div>
    <div class="mt-5 border bg-gray-50 rounded-lg p-4">
      <SwitchGroup as="div" class="flex items-center justify-between">
        <span class="flex-grow flex flex-col">
          <SwitchLabel as="span" class="font-medium text-gray-800" passive>
            Apply a fee for extra adults / night
          </SwitchLabel>
        </span>
        <Switch v-model="isApplyAdultsFee"
                :class="[isApplyAdultsFee ? 'bg-highlight-600' : 'bg-gray-200', 'relative inline-flex ' +
                  'flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer ' +
                  'transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ' +
                  'focus:ring-highlight-500']">
          <span aria-hidden="true" :class="[isApplyAdultsFee ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none ' +
            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
        </Switch>
      </SwitchGroup>
      <div>
        <div v-if="isApplyAdultsFee">
          <p class="text-gray-600 leading-10">
            Apply a fee of
            <SmInputNumber
              id="extra-guest-value"
              v-model:value="additionalAdult.price"
              label="Extra guest value"
              :input-suffix="currencyCode"
              min="1"
              max="50" /> per adult when more than {{ capacity }} adult(s).
          </p>
        </div>
      </div>
    </div>
    <div class="mt-5 border bg-gray-50 rounded-lg p-4">
      <SwitchGroup as="div" class="flex items-center justify-between">
        <span class="flex-grow flex flex-col">
          <SwitchLabel as="span" class="font-medium text-gray-800" passive>
            Apply a fee for children / night
          </SwitchLabel>
        </span>
        <Switch v-model="isApplyChildrenFee"
                :class="[isApplyChildrenFee ? 'bg-highlight-600' : 'bg-gray-200', 'relative inline-flex ' +
                  'flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer ' +
                  'transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ' +
                  'focus:ring-highlight-500']">
          <span aria-hidden="true" :class="[isApplyChildrenFee ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none ' +
            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
        </Switch>
      </SwitchGroup>
      <div>
        <div v-if="isApplyChildrenFee">
          <div>
            <p class="text-gray-600 leading-10">
              Apply a fee of
              <SmInputNumber
                id="extra-guest-value"
                v-model:value="additionalChild.price"
                label="Extra guest value"
                :input-suffix="currencyCode"
                min="1"
                max="50" /> per child.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 border bg-gray-50 rounded-lg p-4">
      <SwitchGroup as="div" class="flex items-center justify-between">
        <span class="flex-grow flex flex-col">
          <SwitchLabel as="span" class="font-medium text-gray-800" passive>
            Infants (less than 2 years old)
          </SwitchLabel>
          <SwitchDescription as="span" class="text-sm text-gray-500">
            No extra fee will be added for infants.
          </SwitchDescription>
        </span>
        <Switch v-model="isApplyInfantsFee"
                :class="[isApplyInfantsFee ? 'bg-highlight-600' : 'bg-gray-200', 'relative inline-flex ' +
                  'flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer ' +
                  'transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ' +
                  'focus:ring-highlight-500']">
          <span aria-hidden="true" :class="[isApplyInfantsFee ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none ' +
            'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
        </Switch>
      </SwitchGroup>
    </div>
  </SectionWrapper>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import * as yup from "yup";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import { v4 as uuidv4 } from "uuid";
import InputNumberNight from "@/components/UI/Inputs/InputNumberNight";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import SmInputNumber from "@/components/UI/Inputs/SmInputNumber";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "Guests",
  components: {
    InputNumberNight,
    SectionWrapper,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    SmInputNumber
  },
  emits: ['checkIsUnsaved', 'checkIsValid', 'updateData'],
  setup(_, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isCreateProductMode = computed(() => store.state.product.mode === 'create');

    const units = computed(() => store.state.product.product.children);
    const unit = computed(() => store.getters["product/getChildById"](isCreateProductMode.value ?
      'create' : +route.meta.props.unitsList[0].id));
    const nextCreateStep = computed(() => store.getters["product/getNextStepCreate"]);

    const isInfantsEnabled = ref(false);

    const schema = yup.object().shape({
      maxCapacity: yup.number().required().nullable()
        // eslint-disable-next-line consistent-return
        .when('capacity', (capacity) => {
          if (capacity) {
            return yup.number().positive().transform((v, o) => (o === '' ? 0 : v)).nullable()
              .min(capacity, 'Maximum amount of guests should be more than regular amount')
          }
        }),
      capacity: yup.number().required().nullable()
        .transform((v, o) => (o === '' ? 0 : v))
        // eslint-disable-next-line consistent-return
        .when('maxCapacity', (maxCapacity) => {
          if (maxCapacity) {
            return yup.number().min(1).nullable()
              .max(maxCapacity, 'Regular amount of guests should be less than maximum amount of guests')
          }
        }),
      amountPersonRules: yup.array().of(yup.object())
    }, ['capacity', 'maxCapacity']);

    const { errors, handleSubmit, values, isSubmitting, submitCount } = useForm({
      initialValues: {
        capacity: unit.value.capacity,
        maxCapacity: unit.value.max_capacity,
        additionalAdult: unit.value.price_settings ? unit.value.price_settings.find(setting => setting.type === 1) || {
          active: false,
          id: uuidv4(),
          price: 0,
          type: 1,
          value: unit.value.capacity
        } : {
          active: false,
          id: uuidv4(),
          price: 0,
          type: 1,
          value: unit.value.capacity
        },
        additionalChild: unit.value.price_settings ? unit.value.price_settings.find(setting => setting.type === 4) || {
          active: false,
          id: uuidv4(),
          price: 0,
          type: 4,
          value: unit.value.capacity
        } : {
          active: false,
          id: uuidv4(),
          price: 0,
          type: 4,
          value: unit.value.capacity
        },
        additionalInfant: unit.value.price_settings ? unit.value.price_settings.find(setting => setting.type === 5) || {
          active: false,
          id: uuidv4(),
          price: 0,
          type: 5,
          value: 0
        } : {
          active: false,
          id: uuidv4(),
          price: 0,
          type: 5,
          value: 0
        }
      },
      validationSchema: schema
    })

    const { value: capacity } = useField('capacity');
    const { value: maxCapacity } = useField('maxCapacity');
    const { value: additionalAdult } = useField('additionalAdult');
    const { value: additionalChild } = useField('additionalChild');
    const { value: additionalInfant } = useField('additionalInfant');

    const isSectionDirty = useIsFormDirty();
    const isSectionValid = useIsFormValid();

    const isValidSection = computed(() => isSectionDirty.value && isSectionValid.value);

    const isUnsaved = computed(() => isSectionDirty.value && submitCount.value === 0);

    watch(isSectionValid, value => emit('checkIsValid', 'guests', value));

    watch(isUnsaved, value => {
      emit('checkIsUnsaved', {
        title: 'Guests'
      }, value)
    });

    emit('updateData', 'guests', values);

    watch(values, value => {
      emit('updateData', 'guests', value);
    });

    const isApplyAdultsFee = ref(additionalAdult.value.active);

    const isApplyChildrenFee = ref(additionalChild.value.active);

    const isApplyInfantsFee = ref(additionalInfant.value.active);

    watch(isApplyAdultsFee, value => {
      additionalAdult.value = {
        ...additionalAdult.value,
        active: value
      };
    });

    watch(isApplyChildrenFee, value => {
      additionalChild.value = {
        ...additionalChild.value,
        active: value
      };
    })

    watch(isApplyInfantsFee, value => {
      additionalInfant.value = {
        ...additionalInfant.value,
        active: value
      };
    })

    const submit = handleSubmit( async ({
      capacity,
      maxCapacity,
      additionalAdult,
      additionalChild,
      additionalInfant
    }, { resetForm }) => {
      if(isValidSection.value) {
        const reset = () => {
          resetForm({
            touched: false,
            values: {
              capacity: unit.value.capacity,
              maxCapacity: unit.value.max_capacity,
              additionalAdult: unit.value.price_settings.find(setting => setting.type === 1),
              additionalChild: unit.value.price_settings.find(setting => setting.type === 4),
              additionalInfant: unit.value.price_settings.find(setting => setting.type === 5)
            }
          })
        }
        if(isCreateProductMode.value) {
          store.commit('product/setData', { field: 'children', step: 'children-guests', data: [{
               ...unit.value,
               capacity: +capacity,
               max_capacity: +maxCapacity,
               price_settings: [
                 ...unit.value.price_settings,
                 additionalAdult,
                 additionalChild,
                 additionalInfant
               ]
          }] });
          reset();
          await router.push(nextCreateStep.value);
        } else {
          try {
            const priceSettings = [
              ...(unit.value.price_settings ?? []).filter(setting => ![1,4,5].includes(setting.type)),
              {
                ...additionalAdult,
                value: capacity.value,
                id: typeof additionalAdult.id === 'string' ? undefined : additionalAdult.id
              },
              {
                ...additionalChild,
                value: capacity.value,
                id: typeof additionalChild.id === 'string' ? undefined : additionalChild.id
              },
              {
                ...additionalInfant,
                active: isApplyInfantsFee.value,
                id: typeof additionalInfant.id === 'string' ? undefined : additionalInfant.id
              }
            ].map(setting => ({
              ...setting,
              __typename: undefined
            }));

            const preparedData = {
              children: units.value.map(child => {
                if(child.id === +route.meta.props.unitsList[0].id) {
                  return {
                    id: child.id,
                    default_price: +unit.value.default_price,
                    capacity: +capacity,
                    max_capacity: +maxCapacity,
                    price_settings: priceSettings
                  }
                }
                return {
                  ...child,
                  __typename: undefined,
                  translation: undefined,
                  translations: child.translations.map(translation => ({
                    ...translation,
                    locale: 'en',
                    __typename: undefined
                  })),
                  price_settings: child.price_settings.map(setting => ({
                    ...setting,
                    __typename: undefined
                  })),
                  synchronization: child.synchronization.map(calendar => ({
                    ...calendar,
                    __typename: undefined
                  }))
                };
              })
            };

            await store.dispatch('product/updateProduct', { id: +route.params.id, data: preparedData }).then(() => {
              reset();
            });
          } catch (err) {
            notify({
              text: err,
              type: 'error'
            })
          }
        }
      }
    });

    const currencyCode = computed(() => store.getters["product/getCurrentCurrencyCode"]);

    return {
      isApplyChildrenFee,
      isApplyAdultsFee,
      isApplyInfantsFee,
      capacity,
      maxCapacity,
      isValidSection,
      errors,
      submit,
      isInfantsEnabled,
      currencyCode,
      additionalAdult,
      additionalChild,
      additionalInfant,
      isSubmitting
    }
  }
};
</script>

<style scoped></style>
