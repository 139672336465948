<template>
  <div>
    <Pricing
      id="pricing"
      @checkIsUnsaved="checkForUnsavedSections"
      @checkIsValid="setSectionsValidity"
      @updateData="setSectionsData" />
    <TripLength
      id="trip-length"
      @checkIsUnsaved="checkForUnsavedSections"
      @checkIsValid="setSectionsValidity"
      @updateData="setSectionsData" />
    <Guests
      id="guests"
      @checkIsUnsaved="checkForUnsavedSections"
      @checkIsValid="setSectionsValidity"
      @updateData="setSectionsData" />
    <CalSync
      v-if="isEditProductMode"
      id="cal-sync"
      @checkIsUnsaved="checkForUnsavedSections"
      @checkIsValid="setSectionsValidity"
      @updateData="setSectionsData" />
    <UnsavedChangesModal
      :isOpen="isModalOpen"
      :isInvalidSectionsPresent="isInvalidSectionsPresent"
      @toggleModal="cancelModal"
      @continue="moveToNextRoute"
      @saveAndContinue="save">
      <template v-slot:title>
        <span class="text-gray-800">
          You have unsaved changes.
        </span>
        <ul class="hidden text-sm text-water font-normal">
          <li v-for="section in unsavedSections" :key="section">
            {{ section.title }}
          </li>
        </ul>
      </template>
      <template v-slot:content><p class="leading-6">You are about to exit the page. <br />All unsaved changes will be lost.</p></template>
      <template v-slot:buttonName>Discard</template>
    </UnsavedChangesModal>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Pricing from "@/components/views/Listing/PricingAndAvailability/Pricing";
import Guests from "@/components/views/Listing/PricingAndAvailability/Guests";
import TripLength from "@/components/views/Listing/PricingAndAvailability/TripLength";
import CalSync from "@/components/views/Listing/PricingAndAvailability/CalSync";
import UnsavedChangesModal from "@/components/UI/Popups/Modal/AppUnsavedChangesModal";
import { useUnsavedModal } from "@/use/unsavedModal";

export default {
  name: "SubListing",
  components: {
    CalSync,
    TripLength,
    Guests,
    Pricing,
    UnsavedChangesModal
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isEditProductMode = !!route.params.id;

    const units = computed(() => store.state.product.product.children);

    const {
      checkForUnsavedSections,
      setSectionsValidity,
      saveUnsavedSections,
      isInvalidSectionsPresent,
      unsavedSections,
      isModalOpen,
      moveToNextRoute,
      cancelModal
    } = useUnsavedModal(['pricing', 'tripLength', 'guests', 'calSync']);

    const detailsSectionData = ref({
      pricing: null,
      tripLength: null,
      guests: null,
      calSync: null
    });

    const setSectionsData = (section, value) => {
      detailsSectionData.value[section] = value;
    };

    const save = () => {
      const priceSettings = [
        detailsSectionData.value.guests.amountPersonRule,
        detailsSectionData.value.tripLength.amountNightRule,
        ...detailsSectionData.value.pricing.dayRules
      ].map(setting => ({
        ...setting,
        __typename: undefined
      }));

      const preparedData = {
        children: units.value.map(child => {
          if(child.id === +route.meta.props.unitsList[0].id) {
            return {
              id: child.id,
              capacity: +detailsSectionData.value.guests.capacity,
              max_capacity: +detailsSectionData.value.guests.maxCapacity,
              price_settings: priceSettings,
              translations: [
                {
                  locale: 'en',
                  title: detailsSectionData.value.pricing.title
                }
              ],
              default_price: +detailsSectionData.value.pricing.price,
              max_booking_duration: +detailsSectionData.value.tripLength.maxDuration,
              min_booking_duration: +detailsSectionData.value.tripLength.minDuration,
              preparation_after_booking: +detailsSectionData.value.tripLength.bufferPeriod,
              preparation_before_booking: +detailsSectionData.value.tripLength.preparationTime
            }
          }
          return child;
        })
      };

      saveUnsavedSections(preparedData, isEditProductMode);
    };

    return {
      checkForUnsavedSections,
      setSectionsValidity,
      setSectionsData,
      isInvalidSectionsPresent,
      unsavedSections,
      isModalOpen,
      moveToNextRoute,
      cancelModal,
      save,
      detailsSectionData,
      isEditProductMode
    }
  }
};
</script>

<style scoped>

</style>
