<template>
  <div class="mb-2 border rounded-md bg-white overflow-hidden">
    <div class="bg-gray-100 px-4 py-2 flex items-center justify-between border-b">
      <h3 class="font-medium text-gray-700">{{`Rule #${ruleNumber}`}}</h3>
      <button class="p-1.5 rounded-md text-red-500 btn-xs btn-transparent flex gap-1" @click="$emit('removeRule', rule.id)">
        <TrashIcon class="h-4 w-4" />Delete
      </button>
    </div>
    <div class="p-3 sm:p-5">
      <div class="pt-3 mb-2">
        <p class="mt-3 text-gray-600 leading-7">
          Give a daily discount of
          <span class="inline relative">
            <input
              id="length-discount"
              v-model="rulePrice"
              name="length-discount"
              type="number"
              min="1"
              max="50"
              class="no-arrows text-right inline w-12 h-7 border border-gray-300 mx-1
              border pl-1 pr-5 py-0.5 rounded-md" />
            <span class="absolute right-3 text-gray-500 text-sm top-1/2 -translate-y-1/2">%</span>
          </span>
          if more than
          <input
            id="discount-nights"
            v-model="ruleValue"
            name="discount-nights"
            type="number"
            min="2"
            max="50"
            class="no-arrows text-center inline w-auto h-7 border border-gray-300 mx-1
              border px-1 py-0.5 rounded-md" />
          nights. The discount will only be applied to dates that exceed {{ ruleValue }}.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, watch } from "vue";
import * as yup from "yup";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";

export default {
  name: "LengthRule",
  props: {
    ruleNumber: {
      type: Number,
      required: true
    },
    rule: {
      type: Object,
      required: true
    }
  },
  emits: ['updateRule', 'removeRule'],
  setup(prop, { emit }) {
    const store = useStore();
    const currencyCode = computed(() => store.getters["product/getCurrentCurrencyCode"]);

    const schema = yup.object({
      ruleValue: yup.string().required(),
      rulePrice: yup.string().required()
    });

    const { errors, values } = useForm({
      initialValues: {
        ruleValue: prop.rule.value,
        rulePrice: prop.rule.price
      },
      validationSchema: schema,
      validateOnMount: true
    })

    const { value: ruleValue } = useField('ruleValue');
    const { value: rulePrice } = useField('rulePrice');

    const isGeneralDirty = useIsFormDirty();
    const isGeneralValid = useIsFormValid();

    const isValidSection = computed(() => isGeneralDirty.value && isGeneralValid.value);

    watch(values, value => {
      emit('updateRule', prop.rule.id, value.rulePrice, value.ruleValue, prop.rule.type);
    })


    return {
      rulePrice,
      ruleValue,
      currencyCode,
      errors,
      isValidSection
    }
  }
};
</script>

<style scoped>

</style>
