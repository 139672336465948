<template>
  <div class="mb-2 border rounded-md bg-white overflow-hidden">
    <div class="bg-gray-100 px-4 py-2 flex items-center justify-between border-b">
      <h3 class="font-medium text-gray-700">{{`Rule #${ruleNumber}`}}</h3>
      <button class="p-1.5 rounded-md text-red-500 btn-xs btn-transparent flex gap-1" @click="$emit('removeRule', rule.id)">
        <TrashIcon class="h-4 w-4" />Delete
      </button>
    </div>
    <div class="p-3 sm:p-5">
      <div class="mb-4">
        <h4 class="text-sm text-gray-700">Select days</h4>
        <RadioGroup v-model="selectedDay">
          <div class="grid grid-cols-3 md:grid-cols-4 gap-2">
            <RadioGroupOption
              v-for="day in preparedDays"
              :key="day.value"
              :value="day.value"
              :disabled="!day.active"
              as="template"
              v-slot="{ checked, disabled }">
              <span
                :class="[
                  checked ? 'border rounded text-center bg-highlight-100 px-2 py-1 text-sm' +
                    ' cursor-pointer text-highlight-600 border-highlight-500' :
                  'border border-gray-400 rounded text-center bg-white ' +
                  'px-2 py-1 text-sm text-gray-600',
                  disabled ? 'bg-gray-200' : 'hover:bg-gray-50 cursor-pointer'
                ]">
                {{ day.text }}
              </span>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </div>
      <InputNumberNight id="special-day-price" v-model:value="price" label="Price per night" :input-suffix="currencyCode" input-class="w-20" />
      <div v-if="price && dayTitle">
        <p class="mt-5 font-medium text-sm text-gray-700">Summary</p>
        <p class="text-sm text-gray-700 mt-2">{{ `${dayTitle}, the default price will be ${price} ${currencyCode}/night` }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import * as yup from "yup";
import { TrashIcon } from "@heroicons/vue/solid";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import InputNumberNight from "@/components/UI/Inputs/InputNumberNight";

export default {
  name: "DayRule",
  components: {
    InputNumberNight,
    TrashIcon
  },
  props: {
    ruleNumber: {
      type: Number,
      required: true
    },
    rule: {
      type: Object,
      required: true
    },
    preparedDays: {
      type: Array,
      required: true
    }
  },
  emits: ['updateRule', 'removeRule'],
  setup(prop, { emit }) {
    const store = useStore();
    const currencyCode = computed(() => store.getters["product/getCurrentCurrencyCode"]);

    const schema = yup.object({
      selectedDay: yup.string().required().nullable(),
      price: yup.string().required()
    });

    const { errors, values } = useForm({
      initialValues: {
        selectedDay: prop.rule.value,
        price: prop.rule.price
      },
      validationSchema: schema,
      validateOnMount: true
    })

    const { value: selectedDay } = useField('selectedDay');
    const { value: price } = useField('price');

    const isGeneralDirty = useIsFormDirty();
    const isGeneralValid = useIsFormValid();

    const isValidSection = computed(() => isGeneralDirty.value && isGeneralValid.value);

    watch(values, value => {
      emit('updateRule', prop.rule.id, value.price, value.selectedDay, prop.rule.type);
    })

    const dayTitle = computed(() => prop.preparedDays.find(day => day.value === selectedDay.value).text);

    return {
      selectedDay,
      price,
      currencyCode,
      errors,
      isValidSection,
      dayTitle
    }
  }
};
</script>

<style scoped>

</style>
