<template>
  <div class="border rounded-lg overflow-hidden">
    <div class="bg-gray-50 p-2 flex justify-between items-center">
      <div class="flex items-center gap-2">
        <p class="text-gray-600">
          <span class="font-medium text-gray-700">{{`#${number}`}}</span> <span class="mx-1">|</span>
          <span class="text-sm">{{`${selectedChannel?.title || ''}`}}</span>
        </p>
      </div>
      <div class="flex gap-2">
        <button
          v-if="isSectionEditable"
          class="p-1.5 rounded-md text-red-500 btn-xs btn-transparent flex gap-1"
          @click="$emit('removeLink', syncItem.id)">
          <TrashIcon class="h-4 w-4" />
          <span>Delete</span>
        </button>
        <button
          class="btn btn-xs btn-transparent"
          @click="toggleSectionEdit">
          <span v-if="!isSectionEditable">Edit</span>
          <span v-else>OK</span>
        </button>
      </div>
    </div>
    <div class="p-4 md:p-6">
      <div class="space-y-4">
        <div v-if="isSectionEditable" class="space-y-2 pb-5">
          <!--          <div class="max-w-xs">-->
          <!--            <label for="name" class="block text-sm font-medium text-gray-700">Name</label>-->
          <!--            <div class="mt-1 flex rounded-md shadow-sm">-->
          <!--              <input-->
          <!--                id="name"-->
          <!--                type="text"-->
          <!--                name="name"-->
          <!--                class="flex-1 min-w-0 block w-full px-3 py-2 rounded-md focus:ring-highlight-500-->
          <!--              focus:border-highlight-500 sm:text-sm border-gray-300"-->
          <!--                placeholder="The name" />-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="w-44">
            <SelectorUI v-model:value="selectedChannel" :items="channels" label="Service:" />
          </div>
          <p class="mt-1 text-sm text-gray-500">
            Your service not on the list?
            <a
              href="https://owayy.com/contact-us"
              target="_blank"
              class="text-highlight-600 underline font-medium">
              Contact us
            </a>
          </p>
        </div>
        <div class="max-w-lg">
          <label for="import-link" class="block text-sm font-medium text-gray-700"> Import link </label>
          <div class="mt-1 flex rounded-md shadow-sm">
            <input
              id="import-link"
              v-model="linkInput"
              type="text"
              name="import-link"
              class="flex-1 min-w-0 block w-full px-2 py-2 rounded-none rounded-l-md focus:ring-highlight-500
              focus:border-highlight-500 sm:text-sm border-gray-300"
              placeholder="www.example.com" />
            <button
              class="inline-flex items-center px-4 rounded-r-md border border-l-0
              border-gray-300 sm:text-sm"
              :class="errors.link || !linkInput ? 'pointer-events-none text-gray-300 bg-gray-50'
                : 'text-white border-highlight-600 bg-highlight-600'"
              @click="validateLink">
              <span v-if="checkingValidation">
                <LoadingSpinner outer-class="h-5 w-5 text-white" />
              </span>
              <span v-else>Save</span>
            </button>
          </div>
          <span class="input-error">{{ errors.linkInput }}</span>
          <span class="input-error">{{ validationMessage }}</span>
        </div>
        <div class="max-w-lg">
          <label for="export-link" class="block text-sm font-medium text-gray-700"> Export link </label>
          <div class="mt-1 flex rounded-md shadow-sm">
            <button
              class="inline-flex items-center px-2 rounded-l-md border border-r-0 border-gray-300 bg-gray-50
              text-gray-500 sm:text-sm"
              @click="copyLink(exportCalendarUrl)">
              <DocumentDuplicateIcon class="text-gray-600 h-5 w-5" />
            </button>
            <input
              id="export-link"
              v-model="exportCalendarUrl"
              type="text"
              name="import-link"
              readonly
              class="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md
                sm:text-sm border-gray-300 text-gray-800" />
          </div>
          <p class="text-gray-600 text-sm mt-1">Copy and paste this link into other ICAL applications</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useQuery } from "@vue/apollo-composable";
import * as yup from "yup";
import { useField, useForm, useIsFormValid } from "vee-validate";
import SelectorUI from "@/components/UI/Inputs/SelectorUI";
import { GET_CHANNEL_URL } from "@/graphql/products/channels/channels";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";

export default {
  name: "SyncItem",
  components: { LoadingSpinner, SelectorUI },
  component: {
    SelectorUI
  },
  props: {
    syncItem: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    channels: {
      type: Array,
      required: true
    }
  },
  emits: ['removeLink', 'selectChannel', 'inputImportUrl'],
  setup(prop,  { emit }) {
    const route = useRoute();

    const mappedChannels = ref(prop.channels.map(channel => ({
      ...channel,
      __typename: undefined
    })));

    const selectedChannel = ref(
      mappedChannels.value.find(channel => channel.id === prop.syncItem.channel_id) || null);

    const isSectionEditable = ref(false);

    if (!selectedChannel.value) {
      isSectionEditable.value = true;
    }


    watch(selectedChannel, value => {
      emit('selectChannel', value, prop.syncItem.id)
    });

    const { result } = useQuery(GET_CHANNEL_URL, () => ({
      channelId: selectedChannel.value?.id,
      productId: +route.meta.props.unitsList[0].id
    }), () => ({ fetchPolicy:  'network-only', enabled: selectedChannel.value !== null }));

    const exportCalendarUrl = computed(() => result.value?.getLinkByChannel ?? []);

    const toggleSectionEdit = () => {
      isSectionEditable.value = !isSectionEditable.value;
    };

    const copyLink = (textToCopy) => {
      navigator.clipboard.writeText(textToCopy);
    }

    const schema = yup.object({
      linkInput: yup.string()
        .required('Please enter ical link')
    });

    const { errors } = useForm({
      initialValues: {
        linkInput: prop.syncItem.link
      },
      validationSchema: schema,
      validateOnMount: false
    })

    const { value: linkInput } = useField('linkInput');

    const isSectionValid = useIsFormValid();

    const isLinkValid =  ref(true);
    const validationMessage = ref('');
    const checkingValidation = ref(false);

    watch([isSectionValid, isLinkValid], ([section, linkInput]) => {
      emit('checkLink', section && linkInput);
      isLinkValid.value = section && linkInput;
    })

    const validateLink = async () => {
      checkingValidation.value = true;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-key": process.env.VUE_APP_VALIDATE_KEY
        },
        body: JSON.stringify({
          channel_id: selectedChannel.value.id,
          link: linkInput.value
        })
      };

      await fetch(process.env.VUE_APP_VALIDATE_LINK_URL, requestOptions)
        .then(response => response.json())
        .then(data => {
          isLinkValid.value = data.verified;
          validationMessage.value = data.error;
          emit('inputImportUrl', linkInput.value, prop.syncItem.id)
        }).finally(() => {
          checkingValidation.value = false;
        });
    }



    return {
      toggleSectionEdit,
      copyLink,
      isSectionEditable,
      mappedChannels,
      selectedChannel,
      exportCalendarUrl,
      errors,
      isSectionValid,
      linkInput,
      validateLink,
      validationMessage,
      checkingValidation
    }
  }
};
</script>

<style scoped>

</style>
